<template>
  <div :class="{ ' MainSection': true, 'MainSection-left': id % 2 === 1 }">
    <div :id="id" class="SubSection">
      <div :id="id" class="SubSectionTxt">
        <h3 :id="id">{{ CheckupHeader }}</h3>
        <p :id="id">{{ CheckupDescription }}</p>
      </div>
    </div>
    <div :id="id" class="SubSection">
      <img :id="id" :src="image" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailsServices",
  props: {
    id: Number,
    CheckupHeader: String,
    CheckupDescription: String,
    image: String,
  },
};
</script>

<style scoped>
.MainSection {
  color: #2e3c8b;
  margin: auto;
  width: 90%;
  display: flex;
  /* height: 500px; */
}
.MainSection-left {
  flex-direction: row-reverse;
}
.SubSection {
  display: grid;
  place-items: center;
  width: 100%;
  margin: 1%;
  background-color: #fafafa;
  line-height: 30px;
}
.SubSection h3 {
  margin-bottom: 8%;
  font-family: "Darker Grotesque";
  color: #ce515c;
}
.SubSection p {
  padding-left: 50px;
  padding-right: 50px;
}
.SubSection img {
  width: inherit;
  height: inherit;
}
@media screen and (max-width: 720px) {
  .MainSection {
    display: block;
  }
  .SubSection p {
  padding-left: 10px;
  padding-right: 10px;
}
}
</style>