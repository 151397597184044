<template>
  <div id="footer">
    <div class="topSection">
      <div class="Txt">
        <p class="SectionOne">
          hospitalsroyal@gmail.com <br />
          +255746044400
        </p>
      </div>
      <!-- <div class="EmailSection">
        <form>
          <table>
            <tr>
              <td colspan="2">
                <p>Subscribe Form</p>
              </td>
            </tr>
            <tr>
              <td>
                <input type="text" placeholder="Email Address" required />
              </td>
              <td>
                <button>Submit</button>
              </td>
            </tr>
          </table>
        </form>
      </div> -->
    </div>
    <div class="icons">
      <ul>
        <li>
          <a
            href="https://www.facebook.com/Royal-Hospitals-107235220787568/"
            target="_blank"
          >
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 155.139 155.139"
              style="enable-background: new 0 0 155.139 155.139"
              xml:space="preserve"
            >
              <g>
                <path
                  id="f_1_"
                  style="fill: #010002"
                  d="M89.584,155.139V84.378h23.742l3.562-27.585H89.584V39.184
		c0-7.984,2.208-13.425,13.67-13.425l14.595-0.006V1.08C115.325,0.752,106.661,0,96.577,0C75.52,0,61.104,12.853,61.104,36.452
		v20.341H37.29v27.585h23.814v70.761H89.584z"
                />
              </g>
            </svg>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/royal_hospitals/" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="31.518"
              height="31.511"
              viewBox="0 0 31.518 31.511"
            >
              <path
                id="Icon_awesome-instagram"
                data-name="Icon awesome-instagram"
                d="M15.757,9.914a8.079,8.079,0,1,0,8.079,8.079A8.066,8.066,0,0,0,15.757,9.914Zm0,13.331a5.252,5.252,0,1,1,5.252-5.252,5.262,5.262,0,0,1-5.252,5.252ZM26.051,9.584A1.884,1.884,0,1,1,24.166,7.7,1.88,1.88,0,0,1,26.051,9.584ZM31.4,11.5a9.325,9.325,0,0,0-2.545-6.6,9.387,9.387,0,0,0-6.6-2.545c-2.6-.148-10.4-.148-13,0a9.373,9.373,0,0,0-6.6,2.538,9.356,9.356,0,0,0-2.545,6.6c-.148,2.6-.148,10.4,0,13a9.325,9.325,0,0,0,2.545,6.6,9.4,9.4,0,0,0,6.6,2.545c2.6.148,10.4.148,13,0a9.325,9.325,0,0,0,6.6-2.545,9.387,9.387,0,0,0,2.545-6.6c.148-2.6.148-10.392,0-12.994ZM28.041,27.281a5.318,5.318,0,0,1-3,3c-2.074.823-7,.633-9.288.633s-7.221.183-9.288-.633a5.318,5.318,0,0,1-3-3c-.823-2.074-.633-7-.633-9.288s-.183-7.221.633-9.288a5.318,5.318,0,0,1,3-3c2.074-.823,7-.633,9.288-.633s7.221-.183,9.288.633a5.318,5.318,0,0,1,3,3c.823,2.074.633,7,.633,9.288S28.863,25.214,28.041,27.281Z"
                transform="translate(0.005 -2.237)"
              />
            </svg>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/HospitalsRoyal" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="29.238"
              viewBox="0 0 36 29.238"
            >
              <path
                id="Icon_awesome-twitter"
                data-name="Icon awesome-twitter"
                d="M32.3,10.668c.023.32.023.64.023.959,0,9.754-7.424,20.992-20.992,20.992A20.85,20.85,0,0,1,0,29.307a15.263,15.263,0,0,0,1.782.091,14.776,14.776,0,0,0,9.16-3.152,7.391,7.391,0,0,1-6.9-5.117,9.3,9.3,0,0,0,1.393.114,7.8,7.8,0,0,0,1.942-.251,7.379,7.379,0,0,1-5.916-7.241V13.66A7.431,7.431,0,0,0,4.8,14.6,7.389,7.389,0,0,1,2.513,4.728a20.972,20.972,0,0,0,15.213,7.721,8.329,8.329,0,0,1-.183-1.69A7.385,7.385,0,0,1,30.312,5.711a14.526,14.526,0,0,0,4.683-1.782,7.358,7.358,0,0,1-3.244,4.066A14.791,14.791,0,0,0,36,6.853a15.86,15.86,0,0,1-3.7,3.815Z"
                transform="translate(0 -3.381)"
              />
            </svg>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/royal-hospitals/"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="31.5"
              height="31.5"
              viewBox="0 0 31.5 31.5"
            >
              <path
                id="Icon_awesome-linkedin"
                data-name="Icon awesome-linkedin"
                d="M29.25,2.25H2.243A2.26,2.26,0,0,0,0,4.521V31.479A2.26,2.26,0,0,0,2.243,33.75H29.25a2.266,2.266,0,0,0,2.25-2.271V4.521A2.266,2.266,0,0,0,29.25,2.25Zm-19.73,27H4.852V14.217H9.527V29.25ZM7.186,12.164A2.707,2.707,0,1,1,9.893,9.457a2.708,2.708,0,0,1-2.707,2.707ZM27.021,29.25H22.352V21.938c0-1.744-.035-3.987-2.426-3.987-2.433,0-2.805,1.9-2.805,3.86V29.25H12.452V14.217h4.479V16.27h.063a4.917,4.917,0,0,1,4.423-2.426c4.725,0,5.6,3.115,5.6,7.165Z"
                transform="translate(0 -2.25)"
              />
            </svg>
          </a>
        </li>
      </ul>
    </div>
    <div class="copyright">
      <p>{{footerdescription}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data(){
    return{
      footerdescription:"Copyright ©"+ this.getYear()+" Royal Hospitals. All rights reserved."
    }
  },
  methods:{
    getYear(){
      var date = new Date();
      var year = date.getFullYear();
      return year

    }
  }
};
</script>

<style scoped>
#footer {
  padding-top: 3%;
  height: 300px;
  width: 70%;
  margin: auto;
}
.topSection {
  width: 100%;
  display: flex;
}
/* .topSection div {
  background-color: #542354;
  width: 50%;
  display: grid;
  place-items: right;
} */
form {
  width: 100%;
}
.EmailSection table {
  width: 100%;
}
.EmailSection table input {
  width: 100%;
  padding: 7px 20px 7px 20px;
  border: 1px solid #2e3c8b;
  color: #2e3c8b;
}
::placeholder {
  color: #2e3c8b;
}
.Txt {
  width: 50%;
}
.Txt .SectionOne {
  text-align: left;
  line-height: 30px;
  color: #2e3c8b;
}
tr p {
  text-align: right;
  padding-right: 5%;
  font-size: 16px;
  color: #2e3c8b;
}
.EmailSection {
  width: 50%;
}
button {
  color: #fff;
  background-color: #2e3c8b;
  border: unset;
  padding: 9px 23px 9px 23px;
}
.icons ul {
  margin-top: 50px;
  padding: unset;
  /* width:200px; */
  display: flex;
}
.icons ul li {
  list-style: none;
  padding-right: 2%;
}
.icons ul li svg {
  width: 15px;
  height: 15px;
  fill: #000;
}
.icons ul li svg:hover {
  fill: rgb(112, 112, 112);
}
.copyright {
  margin-top: 4%;
}
.copyright p {
  color: #2e3c8b;
  text-align: left;
}
</style>