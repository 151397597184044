<template>
  <div id="checkups">
    <div class="checkupsStyle">
      <TitlePage
        :showButton="false"
        class="home-title"
        :header="header"
        :description="description"
      />
    </div>
    <DetailsTemplate
      v-for="service in services"
      :key="service.id"
      :id="service.id"
      :CheckupHeader="service.CheckupHeader"
      :CheckupDescription="service.CheckupDescription"
      :image="service.checkupImage"
    />
    <router-link to="/contact"
      ><button class="about-btn">Make An Appointment</button></router-link
    >
    <Footer />
  </div>
</template>

<script>
import TitlePage from "../components/layouts/TitlePage";
import DetailsTemplate from "../components/layouts/DetailsTemplate";
import Footer from "../components/Footer";

export default {
  name: "Checkups",
  components: {
    TitlePage,
    DetailsTemplate,
    Footer,
  },
  data() {
    return {
      header: "Checkups",
      description:
        "We offer a range of Checkups to help our community determine a range of different metrics with regards to their health.",

      services: [
        {
          id: 0,
          CheckupHeader: "General Health Checkup",
          CheckupDescription:
            "Hemoglobin, PCV, RBC, MCHC, MCV, MCH, Total Count, Differential Count, ESR, Peripheral Smear, Platelet Count, Blood Grouping & RH typing.",
          checkupImage: require("@/assets/checkupsImages/0499e6_1eccb179108b4c57a146e2cf0d834dd8_mv2.webp"),
        },
        {
          id: 1,
          CheckupHeader: "Well Woman Checkup",
          CheckupDescription:
            "Complete Blood Count, Fasting & PP, Lipid Profile, BUN, Creatinine, Vitamin D3, TSH, Pap smear Test, Pelvic / Abdomen Scan, Registration, Doctor Consultation.",
          checkupImage: require("@/assets/images/black-women-casual-daylight-eyeglasses-1.webp"),
        },
        {
          id: 2,
          CheckupHeader: "Executive Checkup",
          CheckupDescription:
            "All the tests in the General Health Pack, ECHO Cardiogram, Function, Pap Smear (for Women), PSA for MEN, Registration, Doctor Consultation.",
          checkupImage: require("@/assets/checkupsImages/0499e6_d02f948382d0475a968b5c490e451300_mv2.webp"),
        },
        {
          id: 3,
          CheckupHeader: "Well Women Checkup 2",
          CheckupDescription:
            "Hemoglobin, Platelet, PCV, Total Count, Differential Count, ESR, Random Blood Glucose, Complete Urine Analysis, Stool Analysis (Optional), Ultrasound Scan of Pelvis, Pap-smear Test, Registration, Doctor Consultation.",
          checkupImage: require("@/assets/checkupsImages/0499e6_2ef6f7e755b744ecb7203a6de21f3516_mv2.webp"),
        },
        {
          id: 4,
          CheckupHeader: "Executive Checkup 2",
          CheckupDescription:
            "Fasting & PP Blood Glucose, Usera, Creatinine, Total Cholesterol, HDL Cholesterol, LDL Cholesterol, Triglycerides, ECG, ECHO (Screening), Registration, Doctor Consultation.",
          checkupImage: require("@/assets/checkupsImages/0499e6_64e8b17b1dc84d68a155f51a93f58eba_mv2.webp"),
        },
        {
          id: 5,
          CheckupHeader: "Cardiac Executive Checkup",
          CheckupDescription:
            "General Health, Executive Checkup, Registration, Cardiac Consultation.",
          checkupImage: require("@/assets/checkupsImages/0499e6_f411cf171ceb46878ab94818397707f9_mv2.webp"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.about-btn {
  height: 40px;
  background-color: #ce515c;
  border: unset;
  color: #fff;
}
button {
  margin-top: 2%;
  transition: 200ms;
  transition-timing-function: ease-in-out;
}
button:hover {
  opacity: 0.9;
}
.checkupsStyle {
  background-image: url("../assets/checkupsImages/0499e6_d02f948382d0475a968b5c490e451300_mv2.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.home-title {
  background-color: rgba(194, 31, 31, 0.5);
  height: 70vh;

  display: grid;
  place-items: center;
}
.CheckupSections {
  display: flex;
  background-color: salmon;
}
</style>