<template>
  <div class="HomeSection">
    <div class="center-Txt">
      <h1>{{ header }}</h1>
      <p>{{ description }}</p>
      <button v-if="showButton" class="about-btn">
        {{ btnTxt }}
      </button>
      <a
        v-show="show"
        :href="require('../../assets/documents/Profile.pdf')"
        download="Profile.pdf"
      >
        <button class="about-btn">
          {{ btnTxt }}
        </button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    header: String,
    description: String,
    showButton: Boolean,
    btnTxt: String,
  },
};
</script>

<style scoped>
.about-btn {
  padding-right: 20px;
  padding-left: 20px;
  height: 40px;
  background-color: #ce515c;
  border-radius: 1px;
  border: unset;
  color: #fff;
}
button,
a {
  margin-top: 2%;
  transition: 200ms;
  transition-timing-function: ease-in-out;
}
button:hover {
  opacity: 0.9;
}
.center-Txt {
  color: #fff;
}
.center-Txt h1 {
  font-size: 60px;
  font-family: "Darker Grotesque";
}
.center-Txt p {
  font-family: "Darker Grotesque";
  margin: auto;
  font-size: 25px;
  padding: 0px 100px 0px 100px;
}
@media screen and (max-width: 720px) {
  .center-Txt p {
    padding: 0px 10px 0px 10px;
  }
}
@media screen and (max-width: 360px) {
  .center-Txt p {
    font-size: 22px;
  }
}
</style>